.page-login {
    background: #303030;
    color: #fff;

    a {
        color: #ec6212;
    }

    img {
        width: 100%;
    }
    .login-box {
        padding: 2rem 2.5rem;
        background-color: #404040;;
        border-radius: 4px;
        box-shadow: 0 10px 15px -3px rgba(0, 0, 0, .3), 0 4px 6px -4px rgba(0, 0, 0, .3);
        margin-top: 600px;
        max-width: 500px;
    }

    .login-wrapper {
        align-items: center;
        display: flex;
        flex-direction: column;
        margin: 0 auto;
        max-width: 28rem;
        width: 94%;
    }

    @media (min-width: 992px) {
        .login-wrapper {
            margin-top: -600px;
            width: 100%;
        }
    }

    input {
        background-color: #303030;
        background-repeat: no-repeat;
        border: 1px solid #737373;
        box-shadow: none;
        color: #e5e5e5;
        font-size: 16px;
        height: 38px;
        line-height: 38px;
        padding: 3px 7px 4px;
        transition: box-shadow .08s ease-in, color .08s ease-in;
        white-space: nowrap;
        word-break: keep-all;
    }

    .form-check-input {
        height: 1em;
    }

    .form-control:focus, .form-check-input:focus {
        background-color: #303030 !important;
        border-color: #a3a3a3 !important;
        box-shadow: none !important;
        outline: 0;
        color: #e5e5e5;
    }
}

@media(min-width: 992px) {
    body.page-login {
        align-items:center;
        display: flex;
        height: 100vh;
        justify-content: center;
        overflow: hidden;
        position: absolute;
        width: 100vw
    }
}