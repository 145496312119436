:root {
    --input-bg-color-recommended: #ffffd3
}

input[recommended]:not([required])[value=""],
input[recommended]:not([required])[value="0"],
input[recommended]:not([required]):not([value]) {
    background-color: var(--input-bg-color-recommended);
}

input.recommended:not([required])[value=""],
input.recommended:not([required])[value="0"],
input.recommended:not([required]):not([value]) {
    background-color: var(--input-bg-color-recommended);
}

/* select2 dropdowns don't inherit the original input element's classes. So we have to do some sillyness to make this work for select2 inputs */
select.select2[recommended]:not(:has(option[data-select2-id])) + .select2-container .select2-selection,
select.select2[recommended]:has(option[data-select2-id][value=""]) + .select2-container .select2-selection {
    background-color: var(--input-bg-color-recommended);
}