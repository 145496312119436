.category-list {

}

.category-list-in-popup {
    max-width: 1100px;
}

.category-list-item {
    float: left;
    width: 200px;
    margin: 20px 20px 0 0;
    background: #fff;
    text-align: center;
}

.category-list-item-image {
    display: block;
    margin: 10px 0 10px 0;
    text-align: center;
    height: 110px;
    overflow: hidden;
}

.category-list-item-image img {
    max-width: 100%;
}

.category-list-item-name {
    display: block;
    min-height: 60px;
}

#product-category-modal .content {
    background: #f9f9f9;
}

#product-category-modal #selected-product-category {
    margin-right: 25%;
}

.category-list-item {
    border-bottom: 2px solid transparent;
}

.category-list-item:hover {
    border-bottom: 2px solid #FF671B;
}