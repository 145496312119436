.quantity-controls {
    display: flex;
    align-items: center;
    padding: 0 0 0 10px;
}

.quantity-controls__current {
    padding: 0 10px;
}

.quantity-controls__btn {
    display: inline-block;
    text-align: center;
    color: #000;
    height: 36px;
    padding: 0 11px;
    line-height: 36px;
    position: relative;
    font-weight: bold;
    font-size: 22px;
    cursor: pointer;
}

.quantity-controls__btn:hover {
    color: #fff;
    text-decoration: none;
}

.quantity-controls__btn:hover:after {
    background: #F26A27;
}

.quantity-controls__btn:after {
    content: " ";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    background: #cfcfcf;
    z-index: -1;
    transform: skew(-10deg);
}

.quantity-controls__btn--disabled {
    opacity: .3;
    pointer-events: none;
}
