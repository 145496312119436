.xl_filter div.dataTables_filter input {
    width: auto !important;;
    padding: 0.50rem 0.5rem !important;;
}

.xl_filter div.dataTables_filter label {
    font-weight: bold !important;;
}

@media (min-width: 1280px) {
    .xl_filter div.dataTables_filter input {
        width: 500px !important;
    }
}