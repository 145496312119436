/* Bootstrap 4 no longer has btn-xs. This fixes this. */
.btn-group-xs > .btn, .btn-xs {
    padding: .25rem .4rem;
    font-size: .875rem;
    line-height: .5;
    border-radius: .2rem;
}

/* Fix data-size="mini" option for bootstrap toggle */
.toggle-on.btn-xs, .toggle-off.btn-xs {
    padding-top: 6px;
}

.input-group-prepend .input-group-text {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
}

.nav-link {
    color: #ec6212;
    text-decoration: none;
}

.nav-pills .nav-link.active, .nav-pills .show>.nav-link {
    background-color: #ec6212;
    color: #fff;
}

.btn {
    border-color: transparent;
    background-color: #efefef;
}

.btn-primary {
    background-color: #ec6212;
    color: #fff;
    border-color: #ec6212;
}

.btn-primary:hover {
    background-color: #eb6e25;
    border-color: #ec6212;
}

.btn-secondary {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d;
}

.btn-secondary:hover {
    background-color: #5a6268;
    border-color: #545b62;
}

.btn-danger {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545;
}

.btn-success {
    background-color: #198754;
    color: #fff;
    border-color: #198754;
}

.lead {
    font-weight: 400;
}

.card-header {
    padding: 0.75rem 1.25rem;
}

.page-sidebar .nav-item {
    line-height: 1.3em;
}

.form-group {
    margin-bottom: 1rem;
}

.dropdown-item.active, .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #eb6e25;
}

.table-borderless td,
.table-borderless th {
    border: 0;
}

.card-body .card-body {
    background-color: #fdfdfd;
}

.app-switch__wrapper {
    display: flex;
    align-items: center;
}

.app-switch__label {
    margin: 0 0 0 11px;
}

.app-switch {
    position: relative;
    display: inline-block;
    width: 43px;
    height: 22px;
    margin: 0;
    flex-shrink: 0;
}

.app-switch__input {
    opacity: 0;
    width: 0;
    height: 0;
}

.app-switch__slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #dcdcdc;
    transition: .25s;
    border-radius: 17px;
}

.app-switch__slider:before {
    position: absolute;
    content: '';
    height: 18px;
    width: 18px;
    left: 2px;
    bottom: 2px;
    background-color: #211F1F;
    transition: .25s;
    border-radius: 50%;
}

.app-switch__input:checked + .app-switch__slider {
    background-color: #06A02E;
}

.app-switch__input:checked + .app-switch__slider:before {
    transform: translateX(21px);
    background-color: white;
}

@media (min-width: 1600px) {
    .col-xxxl {
        flex: 1 0 0
    }

    .row-cols-xxxl-auto > * {
        flex: 0 0 auto;
        width: auto
    }

    .row-cols-xxxl-1 > * {
        flex: 0 0 auto;
        width: 100%
    }

    .row-cols-xxxl-2 > * {
        flex: 0 0 auto;
        width: 50%
    }

    .row-cols-xxxl-3 > * {
        flex: 0 0 auto;
        width: 33.33333333%
    }

    .row-cols-xxxl-4 > * {
        flex: 0 0 auto;
        width: 25%
    }

    .row-cols-xxxl-5 > * {
        flex: 0 0 auto;
        width: 20%
    }

    .row-cols-xxxl-6 > * {
        flex: 0 0 auto;
        width: 16.66666667%
    }

    .col-xxxl-auto {
        flex: 0 0 auto;
        width: auto
    }

    .col-xxxl-1 {
        flex: 0 0 auto;
        width: 8.33333333%
    }

    .col-xxxl-2 {
        flex: 0 0 auto;
        width: 16.66666667%
    }

    .col-xxxl-3 {
        flex: 0 0 auto;
        width: 25%
    }

    .col-xxxl-4 {
        flex: 0 0 auto;
        width: 33.33333333%
    }

    .col-xxxl-5 {
        flex: 0 0 auto;
        width: 41.66666667%
    }

    .col-xxxl-6 {
        flex: 0 0 auto;
        width: 50%
    }

    .col-xxxl-7 {
        flex: 0 0 auto;
        width: 58.33333333%
    }

    .col-xxxl-8 {
        flex: 0 0 auto;
        width: 66.66666667%
    }

    .col-xxxl-9 {
        flex: 0 0 auto;
        width: 75%
    }

    .col-xxxl-10 {
        flex: 0 0 auto;
        width: 83.33333333%
    }

    .col-xxxl-11 {
        flex: 0 0 auto;
        width: 91.66666667%
    }

    .col-xxxl-12 {
        flex: 0 0 auto;
        width: 100%
    }
}

@media (min-width: 1920px) {
    .col-xxxxl {
        flex: 1 0 0
    }

    .row-cols-xxxxl-auto > * {
        flex: 0 0 auto;
        width: auto
    }

    .row-cols-xxxxl-1 > * {
        flex: 0 0 auto;
        width: 100%
    }

    .row-cols-xxxxl-2 > * {
        flex: 0 0 auto;
        width: 50%
    }

    .row-cols-xxxxl-3 > * {
        flex: 0 0 auto;
        width: 33.33333333%
    }

    .row-cols-xxxxl-4 > * {
        flex: 0 0 auto;
        width: 25%
    }

    .row-cols-xxxxl-5 > * {
        flex: 0 0 auto;
        width: 20%
    }

    .row-cols-xxxxl-6 > * {
        flex: 0 0 auto;
        width: 16.66666667%
    }

    .col-xxxxl-auto {
        flex: 0 0 auto;
        width: auto
    }

    .col-xxxxl-1 {
        flex: 0 0 auto;
        width: 8.33333333%
    }

    .col-xxxxl-2 {
        flex: 0 0 auto;
        width: 16.66666667%
    }

    .col-xxxxl-3 {
        flex: 0 0 auto;
        width: 25%
    }

    .col-xxxxl-4 {
        flex: 0 0 auto;
        width: 33.33333333%
    }

    .col-xxxxl-5 {
        flex: 0 0 auto;
        width: 41.66666667%
    }

    .col-xxxxl-6 {
        flex: 0 0 auto;
        width: 50%
    }

    .col-xxxxl-7 {
        flex: 0 0 auto;
        width: 58.33333333%
    }

    .col-xxxxl-8 {
        flex: 0 0 auto;
        width: 66.66666667%
    }

    .col-xxxxl-9 {
        flex: 0 0 auto;
        width: 75%
    }

    .col-xxxxl-10 {
        flex: 0 0 auto;
        width: 83.33333333%
    }

    .col-xxxxl-11 {
        flex: 0 0 auto;
        width: 91.66666667%
    }

    .col-xxxxl-12 {
        flex: 0 0 auto;
        width: 100%
    }
}