#search-dropdown {
    width: 300px;
    float: right;
    margin-top: 2px;
}

#search-dropdown .input-group-append button {
    -webkit-border-top-left-radius: 0;
    -webkit-border-bottom-left-radius: 0;
    -moz-border-radius-topleft: 0;
    -moz-border-radius-bottomleft: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border: 1px solid #ced4da;
}

#search-dropdown input:focus {
    box-shadow: 0 0 0 .05rem rgba(13,110,253,.25);
}

#search-dropdown .input-group-append button:hover {
    border: 1px solid #ced4da;
    background-color: #efefef;
}

#search-dropdown #search-stats {
    float: right;
    margin-right: 6px;
    margin-top: -10px;
    font-size: 0.8em;
}

#search-dropdown #search-results {
    max-height: 436px;
    width: 500px;
    overflow: auto;
}

#search-dropdown #search-results  a.more-link {
    display: block;
    position: relative;
    text-align: center;
    color: #444;
    border: 1px solid #ddd;
    z-index: 10;
    background-color: #f4f4f4;
    opacity: 0.8;
    text-decoration: none;
    text-transform: lowercase;
    font-size: 0.9em;
}

#search-dropdown #search-results  a.more-link:focus {
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px;
}

#search-dropdown #search-results a.more-link:hover {
    background-color: #eee;
    color: #333;
}

#search-dropdown #search-results a.more-link:after {
    display: inline-block;
    margin-left: 4px;
    line-height: 1;
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    content: "\F0A9";
    text-rendering: auto;
    color: #ec6212;
}

#search-dropdown .search-result-header {
    margin-bottom: 0.2em;
    border-bottom: 1px solid #ced4da;
    height: 15px;
}

#search-dropdown .search-result-footer {
    border-top: 1px solid #ced4da;
    height: 20px;
    margin-top: 1em;
}

#search-dropdown .search-result-footer a {
    float: right;
    margin-right: 11px;
    margin-top: 6px;
    font-size: 14px;
}

#search-results .search-result-row i {
    margin-right: 20px;
    color: #444;
}

#search-results .search-result-row {
    height: 50px;
    padding: 5px;
    color: inherit;
}

#search-results .search-result-row .search-result-icon {
    margin-left: 10px;
    margin-top: 10px;
    width: 28px;
    text-align: center;
    float: left;
}

#search-results .search-result-row .search-result-text {
    width: 340px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: inline-block;
    height: 1.6em;
    font-size: 1em;
    color: rgb(33, 37, 41);
    padding-left: 17px;
    padding-right: 10px;
}

#search-results .search-result-row i {
    margin-right: 20px;
}

#search-results .search-result-row p {
    font-size: 0.8em;
    color: rgb(33, 37, 41);
    padding-left: 55px;
    margin-top: -10px;
}

#search-results .search-result-row b {
    font-weight: bolder;
    color: #ec6212;
}

#search-results .search-result-row small {
    font-size: x-small;
}

#search-results .search-result-row .badge {
    font-size: x-small;
    margin-top: 3px;
    float: right;
    margin-right: 10px;
}