table.table-fixed thead th {
    position: sticky;
    position: -webkit-sticky;
    top: 69px;
    z-index: 2;
}

table.table-fixed th {
    background: #fff;
}
